.schedule {
    padding: 1rem 0;
    
    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-bottom: 1rem;
    }

    &__title {
        display: flex;
        font-weight: 500;
        line-height: 1.1;
        align-items: flex-end;
        margin-bottom: 1rem;

        span {
            width: 2.75rem;
            height: 2.25rem;
            margin-right: 1.25rem;
        }
    }

    &__trams,
    &__bus {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;

        a {
            display: inline-block;
            font-size: .875rem;
            font-weight: 500;
            text-align: center;
            width: 2.125rem;
            height: 1.875rem;
            line-height: 2.125rem;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            color: var(--theme-color-white);
        }
    }

    &__bus {
        flex-direction: column;

        &--night {
            flex-direction: initial;
        }
    }

    &__vehicle {
        .__row {
            display: flex;
            flex-wrap: wrap;
            gap: .5rem;
        }
    }
}

.station {
    &__bar {
        display: flex;
        flex-wrap: wrap;
        gap: .75rem 1rem;
        align-items: center;
        
        path {
            fill: var(--theme-color-green);
            }
            
        @include media-breakpoint-up(sm){
            gap: 1.25rem;
        }
    }

    &__title {
        font-size: 1.5em;
        font-weight: 700;
        line-height: 1.2;

        span {
            font-weight: 400;
        }

        @include media-breakpoint-down(lg){
            &--wide {
                text-align: center;
                width: 100%;
            }
        }
        @include media-breakpoint-down(sm){
            font-size: 1.125em;
            text-align: center;
            width: 100%;
        }
    }

    &__type {
        .__icon {
            width: 2.75rem;
            height: 2.25rem;
        }
    }

    &__number {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        width: 2.5rem;
        height: 1.9rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        // border-radius: 3rem;

        @include media-breakpoint-up(sm){
            width: 2.75rem;
            height: 2.5rem;
        }

        &--big {
            // font-size: 1.5em;
            width: 3.5rem;
            height: 3rem;
        }
    }

    &__buttons {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        align-items: flex-start;

        .btn {
            min-height: 45px;
        }

        &--grid {
            display: grid;
            width: 100%;
            gap: .75rem;

            > * {
                place-content: center;
            }
        }
        
        @include media-breakpoint-down(lg){
            .btn,
            .dropdown,
            button {
                min-width: 100%;
            }
        }
        @include media-breakpoint-up(sm){
            &--grid {
                grid-template-columns: 1fr 1fr;
            }
        }
        @include media-breakpoint-up(lg){
            align-items: flex-end;

            .btn {
                min-width: 311px;
            }
            
            &--grid {
                width: 310px;
                grid-template-columns: 1fr 1fr;

                .btn {
                    min-width: initial;
                }
            }
        }
    }
}

.line-direction {
    &__name {
        font-size: 1.125em;
        padding: .5rem 0;
        margin-bottom: .5rem;

        @include media-breakpoint-down(xl){
            position: sticky;
            text-align: center;
            background-color: var(--theme-color-white-black);
            top: 0;
            z-index: 7;
        }
    }

    &__stops {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            transition: background-color .2s ease-in-out;

            &:not(.odd) {
                background-color: var(--theme-color-gray-20-black);
                
                .wcag-active & {
                    background-color: rgba(255, 255, 255, 0.03);
                }
            }
            // &.show {
            //     &:nth-child(odd) {
            //         background-color: var(--theme-color-gray-30);
            //     }   
            // }

            &:hover,
            &:focus-visible {
                background-color: var(--theme-color-green-20);
            }
        }
    }

    &--mobile {
        display: none;
    }   

    @include media-breakpoint-down(md){
        &--mobile {
            position: sticky;
            text-align: center;
            display: block;
            top: 0;
            z-index: 8;

            h2 {
                font-size: 1em;
                padding: .65rem 0;
            }
        }

        &--desktop {
            display: none;
        }
    }


}

.line-stop {
    position: relative;
    font-size: $fs-body-small;
    padding: .25rem 1rem;

    a {
        display: flex;
        align-items: center;
    }

    &::after,
    &::before {
        content: '';
        position: absolute;
        background-color: var(--theme-color-green);
        bottom: 0;
        height: 50%;
        width: 2px;
        left: 20px;
    }

    &::before {
        top: 0;
        bottom: auto;
    }

    &:first-of-type(){
        &::before {
            display: none;
        }
    }

    &:last-of-type(){
        &::after {
            display: none;
        }
    }

    &__badge {
        position: relative;
        margin-right: 1rem;
        z-index: 5;
        
        span {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 100px;
            border-width: 1px;
            border-style: solid;
        }
    }

    &__time {
        font-size: .875em;
        font-weight: 500;
        text-align: right;
        width: 24px;
        margin-right: 0.5rem;
    }

    &__legend {
        font-size: .875rem !important;
        line-height: 1.1;
        
        td {
            padding-top: .25rem;
            box-shadow: none;
        }
        
        &--first {
            border-top: 1px solid var(--theme-color-gray-20-black);

            td {
                padding-top: .5rem;
            }
        }
    }

    &__spacer {
        td {
            padding-top: .5rem;
        }
    }

    &__zone {
        display: flex;
        margin-left: auto;
        gap: 0.5rem;

        span {
            font-size: .875em;
            color: var(--theme-color-white);
            text-align: center;
            line-height: 20px;
            height: 20px;
            width: 20px;
            border-radius: 2px;
        }
    }

    &--change-zone {
        font-weight: 600;

        span {
            margin-left: 1.7rem;
            display: inline-block;
            text-align: center;
            line-height: 22px;
            font-weight: 500;
            width: 20px;
            height: 20px;
            border-radius: 2px;
            margin-right: 0.45rem;
            color: var(--theme-color-white);
            z-index: 10;
        }
    }

    $zones: A,B,C,D;

    // Zones schema color
    @each $zone in $zones {
        &--zone-#{$zone} {

            &::after,
            &::before {
                background-color: var(--line-zone-#{$zone});
            }

            .line-stop__zone span,
            &.line-stop--change-zone span {
                @if $zone == C  {
                    color: var(--theme-color-black);
                }
                background-color: var(--line-zone-#{$zone});
            }

            .line-stop__badge {
                span {
                    border-color: var(--line-zone-#{$zone});
                    background-color: var(--line-zone-#{$zone});
                }

                &::after,
                &::before {
                    background-color: var(--line-zone-#{$zone});
                }
            }
        }
    }

    &--on-demand {
        .line-stop__badge {
            span {
                background-color: var(--theme-color-white);
            }
        }
    }

    &--extra {
        display: none;

        &.show {
            display: list-item;
        }

        .line-stop__name,
        .line-stop__zone {
            opacity: .65;
        }

       .line-stop__badge {
            opacity: .65;
            margin-left: 1.5rem;

            &::after,
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                height: 24px;
                width: 2px;
                left: 4px;
                z-index: -1;
            }

            &::before {
                top: 0;
                bottom: auto;
            }
       } 

       &--first {
            .line-stop__badge {
                &::after {
                    transform: rotate(-35deg) translate(-9px, -5px);
                    height: 44px;
                }
            }
       }

       &--last {
            .line-stop__badge {
                &::before {
                    transform: rotate(35deg) translate(-9px, 6px);
                    height: 44px;
                }
            }
       }

       &--in {
            .line-stop__badge {
                &::after {
                    display: none;
                }
            }
       }

       &--out {
            .line-stop__badge {
                &::before {
                    display: none;
                }
            }
       }
    }

    &--details {
        .__lp {
            width: 24px;
            text-align: center;
            padding: 0 .45rem;
        }
        .__time {
            width: 48px;
            text-align: center;
            padding: 0 .85rem;
        }
        .__name {
            line-height: 1.2;
        }
    }
}

.line-planner {
    list-style: none;
    padding: 0;
    margin: 0;

    .line-stop {
        display: flex;
        align-items: center;
        font-size: $fs-body-small;
        padding: 0 0 .125rem;

        &::after,
        &::before {
            left: 36px;
            //z-index: -1;
        }

        a {
            transition: color .2s ease-in-out;

            &:hover,
            &:focus-visible {
                color: var(--theme-color-green-white);
            }
        }

        &--extra {
            display: none;
        }
    }

    &__desc {
        font-size: $fs-body-small;
    }
}

.line-table {
    font-size: 1em;
    width: 100%;
    margin-bottom: 3rem;

    @include media-breakpoint-up(lg){
        margin-bottom: 0;
    }

    tr {
        &:nth-child(odd){
            background-color: var(--theme-color-gray-20-black);
        }

        td {
            padding: .3rem .5rem;
            line-height: 1.2;
        }
    }

    &__hours {
        font-weight: 600;
        text-align: center;
    }

    &__minutes {
        span,
        a {
            display: inline-block;
            margin-right: 0.65rem;
        }
    }
}

.modal {
    &-header {
        padding: 1rem 1rem .25rem;
        border-bottom: 0;

        button {
            border: none;
            background: none;
            background-color: transparent;

            path {
                fill: var(--theme-color-black-contrast);
            }
        }
    }
    &-body {
        table {
            width: 100%;

            thead {
                tr {
                    td {
                        padding: .25rem .75rem !important;
                    }
                }
            }
            
            tr {
                font-size: 1em;
                line-height: 1.1;
                vertical-align: middle;

                .__name,
                .line-stop__zone {
                    padding: .25rem;
                }

                &::before,
                &::after {
                    display: none;
                }
            }
        }
    }

    &-content {
        .wcag-active & {
            background-color: var(--theme-color-black);
            color: var(--theme-color-green);
        }
    }
}