html {
    &.wcag-active {
        --wcag-color-black: #1D1D1D;
        --wcag-color-white: #FFFFFF;
        --wcag-color-contrast: #FFFF00;

        --theme-color-black: var(--wcag-color-black);
        --theme-color-gravel: var(--wcag-color-black);
        --theme-color-green: var(--wcag-color-contrast);
        --theme-color-red: var(--wcag-color-black);
        --theme-color-white: var(--wcag-color-black);

        --theme-color-green-80: var(--wcag-color-contrast);
        --theme-color-green-70: var(--wcag-color-contrast);
        --theme-color-green-20: var(--wcag-color-black);
        --theme-color-green-10: var(--wcag-color-black);
        --theme-color-gray-50: var(--wcag-color-contrast);
        --theme-color-gray-20: var(--wcag-color-contrast);

        // --theme-color-outline: var(--wcag-color-contrast);

        --theme-color-background: var(--wcag-color-black);
        --theme-color-text: var(--wcag-color-contrast);

        --theme-color-black-contrast: var(--wcag-color-contrast);
        --theme-color-gravel-contrast: var(--wcag-color-contrast);
        --theme-color-white-contrast: var(--wcag-color-contrast);

        --theme-color-green-black: var(--wcag-color-black);

        --theme-color-black-white: var(--wcag-color-white);
        --theme-color-gravel-white: var(--wcag-color-white);
        --theme-color-green-white: var(--wcag-color-white);

        --theme-color-gray-20-black: var(--wcag-color-black);

        --theme-color-green-70-black: var(--wcag-color-black);

        --line-zone-A: var(--wcag-color-contrast);
        --line-zone-B: var(--wcag-color-contrast);
        --line-zone-C: var(--wcag-color-contrast);
        --line-zone-D: var(--wcag-color-contrast);
    }
}
