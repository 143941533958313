.alerts {
    margin-top: 1.5rem;

    @include media-breakpoint-up(sm){
        margin-top: 2rem;
    }

    &__wrapper {
        @extend .container-xxl;
    }

    &__accordion {
        @extend .accordion;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__item {
        color: var(--theme-color-gravel-contrast);
        border-radius: 0 !important;
        border: 1px solid var(--theme-color-gravel-contrast) !important;

        button {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            padding: .65rem .875rem;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.2;
            color: inherit;
            text-align: left;
            border: 0;
            border-radius: 0;
            overflow-anchor: none;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, border-radius 0.15s ease;
            background-color: transparent;

            &::before {
                content: "";
                width: 1.25rem;
                height: 1.25rem;
                margin-right: 0.75rem;
                background-repeat: no-repeat;
                background-size: cover;
                transform: rotate(180deg);
                background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16C11 15.7348 11.1054 15.4804 11.2929 15.2929C11.4804 15.1054 11.7348 15 12 15C12.2652 15 12.5196 15.1054 12.7071 15.2929C12.8946 15.4804 13 15.7348 13 16ZM12.75 7C12.75 6.80109 12.671 6.61032 12.5303 6.46967C12.3897 6.32902 12.1989 6.25 12 6.25C11.8011 6.25 11.6103 6.32902 11.4697 6.46967C11.329 6.61032 11.25 6.80109 11.25 7V13C11.25 13.1989 11.329 13.3897 11.4697 13.5303C11.6103 13.671 11.8011 13.75 12 13.75C12.1989 13.75 12.3897 13.671 12.5303 13.5303C12.671 13.3897 12.75 13.1989 12.75 13V7Z' fill='%2345484A'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 1.25C6.063 1.25 1.25 6.063 1.25 12C1.25 17.937 6.063 22.75 12 22.75C17.937 22.75 22.75 17.937 22.75 12C22.75 6.063 17.937 1.25 12 1.25ZM2.75 12C2.75 9.54675 3.72455 7.19397 5.45926 5.45926C7.19397 3.72455 9.54675 2.75 12 2.75C14.4533 2.75 16.806 3.72455 18.5407 5.45926C20.2754 7.19397 21.25 9.54675 21.25 12C21.25 14.4533 20.2754 16.806 18.5407 18.5407C16.806 20.2754 14.4533 21.25 12 21.25C9.54675 21.25 7.19397 20.2754 5.45926 18.5407C3.72455 16.806 2.75 14.4533 2.75 12Z' fill='%2345484A'/%3E%3C/svg%3E%0A");
                
                .wcag-active & {
                    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16C11 15.7348 11.1054 15.4804 11.2929 15.2929C11.4804 15.1054 11.7348 15 12 15C12.2652 15 12.5196 15.1054 12.7071 15.2929C12.8946 15.4804 13 15.7348 13 16ZM12.75 7C12.75 6.80109 12.671 6.61032 12.5303 6.46967C12.3897 6.32902 12.1989 6.25 12 6.25C11.8011 6.25 11.6103 6.32902 11.4697 6.46967C11.329 6.61032 11.25 6.80109 11.25 7V13C11.25 13.1989 11.329 13.3897 11.4697 13.5303C11.6103 13.671 11.8011 13.75 12 13.75C12.1989 13.75 12.3897 13.671 12.5303 13.5303C12.671 13.3897 12.75 13.1989 12.75 13V7Z' fill='%23FFFF00' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 1.25C6.063 1.25 1.25 6.063 1.25 12C1.25 17.937 6.063 22.75 12 22.75C17.937 22.75 22.75 17.937 22.75 12C22.75 6.063 17.937 1.25 12 1.25ZM2.75 12C2.75 9.54675 3.72455 7.19397 5.45926 5.45926C7.19397 3.72455 9.54675 2.75 12 2.75C14.4533 2.75 16.806 3.72455 18.5407 5.45926C20.2754 7.19397 21.25 9.54675 21.25 12C21.25 14.4533 20.2754 16.806 18.5407 18.5407C16.806 20.2754 14.4533 21.25 12 21.25C9.54675 21.25 7.19397 20.2754 5.45926 18.5407C3.72455 16.806 2.75 14.4533 2.75 12Z' fill='%23FFFF00'/%3E%3C/svg%3E%0A");
                }
            }

            &::after {
                content: "";
                flex-shrink: 0;
                width: var(--bs-accordion-btn-icon-width);
                height: var(--bs-accordion-btn-icon-width);
                margin-left: auto;
                background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.18 9L16 18.2713L6.82 9L4 11.8543L16 24L28 11.8542L25.18 9Z' fill='%23333333'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: var(--bs-accordion-btn-icon-width);
                transition: var(--bs-accordion-btn-icon-transition);

                .wcag-active & {
                    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.18 9L16 18.2713L6.82 9L4 11.8543L16 24L28 11.8542L25.18 9Z' fill='%23FFFF00'/%3E%3C/svg%3E%0A");
                }
            }

            span {
                transition: border-color .2s ease-in-out;
                border-bottom: 2px solid transparent;
            }
            
            &:not(.collapsed)::after {
                transform: var(--bs-accordion-btn-icon-transform);

                .wcag-active & {
                    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.18 9L16 18.2713L6.82 9L4 11.8543L16 24L28 11.8542L25.18 9Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
                }
            }

            &:focus {
                box-shadow: none;
            }

            &:hover {
                span {
                    border-color: var(--theme-color-gravel-white);
                }
            }

            &:focus-visible {
                color: var(--theme-color-black-white);
                background-color: var(--theme-color-green-20);
                border: 2px solid var(--theme-color-black-white);
            }
        }

        &--warning {
            border: 0 !important;
            color: var(--theme-color-white-contrast);
            background-color: var(--theme-color-red);

            .wcag-active & {
                border: 1px solid var(--theme-color-gravel-contrast) !important;
            }

            button {
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16C11 15.7348 11.1054 15.4804 11.2929 15.2929C11.4804 15.1054 11.7348 15 12 15C12.2652 15 12.5196 15.1054 12.7071 15.2929C12.8946 15.4804 13 15.7348 13 16ZM12.75 7C12.75 6.80109 12.671 6.61032 12.5303 6.46967C12.3897 6.32902 12.1989 6.25 12 6.25C11.8011 6.25 11.6103 6.32902 11.4697 6.46967C11.329 6.61032 11.25 6.80109 11.25 7V13C11.25 13.1989 11.329 13.3897 11.4697 13.5303C11.6103 13.671 11.8011 13.75 12 13.75C12.1989 13.75 12.3897 13.671 12.5303 13.5303C12.671 13.3897 12.75 13.1989 12.75 13V7Z' fill='%23FFFFFF' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 1.25C6.063 1.25 1.25 6.063 1.25 12C1.25 17.937 6.063 22.75 12 22.75C17.937 22.75 22.75 17.937 22.75 12C22.75 6.063 17.937 1.25 12 1.25ZM2.75 12C2.75 9.54675 3.72455 7.19397 5.45926 5.45926C7.19397 3.72455 9.54675 2.75 12 2.75C14.4533 2.75 16.806 3.72455 18.5407 5.45926C20.2754 7.19397 21.25 9.54675 21.25 12C21.25 14.4533 20.2754 16.806 18.5407 18.5407C16.806 20.2754 14.4533 21.25 12 21.25C9.54675 21.25 7.19397 20.2754 5.45926 18.5407C3.72455 16.806 2.75 14.4533 2.75 12Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
                    transform: rotate(0);

                    .wcag-active & {
                        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16C11 15.7348 11.1054 15.4804 11.2929 15.2929C11.4804 15.1054 11.7348 15 12 15C12.2652 15 12.5196 15.1054 12.7071 15.2929C12.8946 15.4804 13 15.7348 13 16ZM12.75 7C12.75 6.80109 12.671 6.61032 12.5303 6.46967C12.3897 6.32902 12.1989 6.25 12 6.25C11.8011 6.25 11.6103 6.32902 11.4697 6.46967C11.329 6.61032 11.25 6.80109 11.25 7V13C11.25 13.1989 11.329 13.3897 11.4697 13.5303C11.6103 13.671 11.8011 13.75 12 13.75C12.1989 13.75 12.3897 13.671 12.5303 13.5303C12.671 13.3897 12.75 13.1989 12.75 13V7Z' fill='%23FFFF00' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 1.25C6.063 1.25 1.25 6.063 1.25 12C1.25 17.937 6.063 22.75 12 22.75C17.937 22.75 22.75 17.937 22.75 12C22.75 6.063 17.937 1.25 12 1.25ZM2.75 12C2.75 9.54675 3.72455 7.19397 5.45926 5.45926C7.19397 3.72455 9.54675 2.75 12 2.75C14.4533 2.75 16.806 3.72455 18.5407 5.45926C20.2754 7.19397 21.25 9.54675 21.25 12C21.25 14.4533 20.2754 16.806 18.5407 18.5407C16.806 20.2754 14.4533 21.25 12 21.25C9.54675 21.25 7.19397 20.2754 5.45926 18.5407C3.72455 16.806 2.75 14.4533 2.75 12Z' fill='%23FFFF00'/%3E%3C/svg%3E%0A");
                    }
                }
                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.18 9L16 18.2713L6.82 9L4 11.8543L16 24L28 11.8542L25.18 9Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
                }
                
                &:hover {
                    span {
                        border-color: var(--theme-white);
                    }
                }

                &:focus-visible {
                    &::after {
                        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.18 9L16 18.2713L6.82 9L4 11.8543L16 24L28 11.8542L25.18 9Z' fill='%23333333'/%3E%3C/svg%3E%0A");
                    }
                    &:before {
                        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16C11 15.7348 11.1054 15.4804 11.2929 15.2929C11.4804 15.1054 11.7348 15 12 15C12.2652 15 12.5196 15.1054 12.7071 15.2929C12.8946 15.4804 13 15.7348 13 16ZM12.75 7C12.75 6.80109 12.671 6.61032 12.5303 6.46967C12.3897 6.32902 12.1989 6.25 12 6.25C11.8011 6.25 11.6103 6.32902 11.4697 6.46967C11.329 6.61032 11.25 6.80109 11.25 7V13C11.25 13.1989 11.329 13.3897 11.4697 13.5303C11.6103 13.671 11.8011 13.75 12 13.75C12.1989 13.75 12.3897 13.671 12.5303 13.5303C12.671 13.3897 12.75 13.1989 12.75 13V7Z' fill='%2345484A' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 1.25C6.063 1.25 1.25 6.063 1.25 12C1.25 17.937 6.063 22.75 12 22.75C17.937 22.75 22.75 17.937 22.75 12C22.75 6.063 17.937 1.25 12 1.25ZM2.75 12C2.75 9.54675 3.72455 7.19397 5.45926 5.45926C7.19397 3.72455 9.54675 2.75 12 2.75C14.4533 2.75 16.806 3.72455 18.5407 5.45926C20.2754 7.19397 21.25 9.54675 21.25 12C21.25 14.4533 20.2754 16.806 18.5407 18.5407C16.806 20.2754 14.4533 21.25 12 21.25C9.54675 21.25 7.19397 20.2754 5.45926 18.5407C3.72455 16.806 2.75 14.4533 2.75 12Z' fill='%2345484A'/%3E%3C/svg%3E%0A");
                    }

                    .wcag-active & {
                        &::after {
                            background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.18 9L16 18.2713L6.82 9L4 11.8543L16 24L28 11.8542L25.18 9Z' fill='%23FFFF00'/%3E%3C/svg%3E%0A");
                        }
                        &:before {
                            background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16C11 15.7348 11.1054 15.4804 11.2929 15.2929C11.4804 15.1054 11.7348 15 12 15C12.2652 15 12.5196 15.1054 12.7071 15.2929C12.8946 15.4804 13 15.7348 13 16ZM12.75 7C12.75 6.80109 12.671 6.61032 12.5303 6.46967C12.3897 6.32902 12.1989 6.25 12 6.25C11.8011 6.25 11.6103 6.32902 11.4697 6.46967C11.329 6.61032 11.25 6.80109 11.25 7V13C11.25 13.1989 11.329 13.3897 11.4697 13.5303C11.6103 13.671 11.8011 13.75 12 13.75C12.1989 13.75 12.3897 13.671 12.5303 13.5303C12.671 13.3897 12.75 13.1989 12.75 13V7Z' fill='%23FFFF00' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 1.25C6.063 1.25 1.25 6.063 1.25 12C1.25 17.937 6.063 22.75 12 22.75C17.937 22.75 22.75 17.937 22.75 12C22.75 6.063 17.937 1.25 12 1.25ZM2.75 12C2.75 9.54675 3.72455 7.19397 5.45926 5.45926C7.19397 3.72455 9.54675 2.75 12 2.75C14.4533 2.75 16.806 3.72455 18.5407 5.45926C20.2754 7.19397 21.25 9.54675 21.25 12C21.25 14.4533 20.2754 16.806 18.5407 18.5407C16.806 20.2754 14.4533 21.25 12 21.25C9.54675 21.25 7.19397 20.2754 5.45926 18.5407C3.72455 16.806 2.75 14.4533 2.75 12Z' fill='%23FFFF00'/%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }
        }
    }

    &__body {
        padding: .25rem 1rem .75rem 2.75rem;
    }
}