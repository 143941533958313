.section {
    padding: 0 0 2rem;
    
    &__title {
        font-size: 1.5em;
        color: var(--theme-color-gravel-contrast);
        margin-bottom: 1.5rem;
    }
}

#main-content {
    @include media-breakpoint-only('xxl') {
        position: relative;
        z-index: 1;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: url('./../img/svg/decor-bg.svg');
            background-repeat: repeat;
            opacity: 0.5; 
            z-index: -1; 
            pointer-events: none;
        }

        .wcag-active &::before {
            background: none;
        }

        & section .container-xxl {
            background-color: var(--theme-white);

            &::before {
                padding-top:1 rem;
            }

            &::after {
                padding-bottom:1 rem;
            }
            
            .wcag-active & {
                background: none;
            }

        }

        & section.home-boxes .container-xxl {
            background-color: var(--theme-color-gray-20); // lub inna właściwość, którą chcesz zastosować

            .wcag-active & {
              background: none;
            }
        }

    }
}