.box-blog {
    &__thumb {
        width: 100%;
        height: 240px;
        margin-bottom: 1.5rem;
        
        @include media-breakpoint-up(sm){
            height: 380px;
        }

        img {
            width: 100%;
            height: 100%;
            @include media-breakpoint-down(lg){
                object-fit: cover;
                object-position: top;    
            }
            @include media-breakpoint-up(xl){
                object-fit: contain;       
            }            
        }
   
    }

    &__title,
    &__desc {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__title {
        @extend .h3;
        font-weight: 500;
        line-height: 1.2;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        color: var(--theme-color-green);
        margin: 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }

    &__desc {
        margin: 1.25rem 0;
    }

    &__date {
        font-size: $fs-body-small;
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
    }

    &--small {
        display: flex;
        border: 1px solid var(--theme-color-gray-50);
    }

    &--small &__thumb {
        width: auto;
        height: 180px;
        aspect-ratio: 1/1;
        margin: 0;
    }

    &--small &__desc {
        margin: .875rem 0;
        -webkit-line-clamp: 2;
        line-clamp: 2;
    }

    &--small &__content {
        padding: 1rem;
    }
}

.home-news .box-blog {
    .box-blog__thumb {
        img {
            object-fit: cover;
            object-position: top; 
        }
    }
}