.transfer-hubs {
    padding: 1rem;
    
    &__container {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        &--left {
            flex-direction: row-reverse;
        }

        &--top {
            flex-direction: column-reverse;
        }

        &--down {
            flex-direction: column;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        font-size: 1.125rem;
        font-weight: 600;
        gap: 1rem;
        margin-bottom: 1rem;
        
        @include media-breakpoint-up(lg){
            font-size: 1.25rem;
        }
    }

    &__title {
        font-size: 1.25rem;
        margin: 0;

        @include media-breakpoint-up(lg){
            font-size: 1.375rem;
        }
    }

    &__logo {
        max-width: 150px;

        img {
            max-width: 100%;
            height: auto;
            max-height: 40px;
        }
    }

    &__message {
        font-size: .875rem;
        background-color: #C81939;
        padding: .5rem 1rem; 
        flex-grow: 1; 
        width: 100%; 
        text-align: center;
        margin-bottom: 1.5rem;
        color: var(--theme-color-white);
        
        a {
            &:hover {
                color: var(--theme-color-white) !important;
            }
        }

        .wcag-active & {
            background-color: var(--theme-color-green);
        }
    }

    &__col {
        width: 100%;

        @include media-breakpoint-up(lg){
            width: calc(50% - .5rem);

            &--12,
            .transfer-hubs__container--top &,
            .transfer-hubs__container--down & {
                width: 100%;
            }
        }

        &:only-child {
            width: 100%;
        }
    }

    &__content {
        position: relative;
        font-weight: 500;
        z-index: 9;
        
        table {
            tr {
                th {
                    &:first-of-type(){
                        width: 1px;
                    }

                    &.__fit{
                        width: 1px;
                        text-align: center;
                        min-width:200px;
                    }

                    small {
                        display: block;
                        font-size: .765rem;
                    }
                }
            }

            tbody {
                transition: filter .2s ease-in-out;
                
                tr {
                    vertical-align: middle;

                    td {
                        text-align: center;

                        &:nth-child(1),
                        &:nth-child(2) {
                            text-align: left;
                        }

                        &.__line {
                            img {
                                width: 100%;
                                height: auto;
                                max-width: 40px;
                                max-height: 40px;
                            }
                        }

                        .soon {
                            animation: text_blinker 1.5s linear infinite;
                        }
                    }
                }
            }

            .__center {
                text-align: center;
            }

            @include media-breakpoint-up(lg){
                font-size: 1.125rem;
            }
            @include media-breakpoint-up(xl){
                font-size: 1.375rem;
            }
        }
    }

    &__image {
        img {
            max-width: 100%;
            height: auto;
        }
    }

    &__loader {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;

        &__bar {
            width: 0;
            height: 2px;
            background-color: var(--theme-color-green);
            transition: width .1s ease-in-out;
        }
    }
}

@-webkit-keyframes text_blinker {
    50% {
        opacity: .05;
    }
}
