.sidebar {
    position: relative;
    height: 100%;
    display: block;
    margin-top: 2rem;

    @include media-breakpoint-up(lg){
        margin-top: 0;
    }

    &__wrapper {
        display: block;

        > div {
            &:not(:last-child){
                margin-bottom: 2rem;
            }
        }

        &.show {
            display: block;
        }

        @include media-breakpoint-up(lg){
            position: sticky;
            top: 6rem;

            > div {
                &:not(:last-child){
                    margin-bottom: 3rem;
                }
            }
        }
    }

    &__item {
        padding: 1.5rem 1rem;
        background-color: var(--theme-color-gray-20-black);

        &--green {
            background-color: var(--theme-color-green-10);
        }

        .wcag-active &{
            border: 1px solid var(--theme-color-green);
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            > li {
                &:not(:last-of-type){
                    padding-bottom: .65rem;
                }

                a {
                    @include target-blank;
                    line-height: 1.2;
                    transition: color .2s ease-in-out;

                    &.active,
                    &:hover,
                    &:focus-visible,
                    &[aria-current="page"]{
                        color: var(--theme-color-green-white);
                    }
                }
            }
        }
    }

    &__header {
        font-weight: 500;
        color: var(--theme-color-green);
    }
}