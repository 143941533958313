.travel-planner {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    .form-control {
        padding: .75rem;
    }

    .btn {
        padding: .65rem .875rem;
    }

    &__types {
        display: flex;
        width: 100%;
        gap: 1rem;

        .btn {
            cursor: pointer;
            flex-grow: 1;
            border-color: var(--theme-color-green);
            color: var(--theme-color-green);
        }

        input {
            &:checked {
                + .btn {
                    color: var(--theme-color-white);
                    background-color: var(--theme-color-green);
                }
            }

            &:hover {
                &:active {
                    color: var(--theme-color-black-white);
                }
            }

            &:focus-visible {
                + .btn {
                    color: var(--theme-color-black-white);
                    outline: 2px dotted var(--theme-color-outline);
                    background-color: var(--theme-color-green-20);
                    border-bottom: $theme-border;
                    border-bottom-color: var(--theme-color-black-white);
                }
            }
        }

        label {
            &:hover {
                color: var(--theme-color-white);
                background-color: var(--theme-color-green-70) !important;
                border-color: var(--theme-color-green-70);
            }

            &:hover {
                &:active {
                    color: var(--theme-color-black-white);
                }
            }
        }
    }

    &__date {
        display: none;
    }

    &__info {
        font-size: 12px;
        line-height: 1.2;
        opacity: .85;
    }
}