.wcag-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: .25rem 0;
    transition: add .25s ease-in-out, opacity .25s ease-in-out, visibility .25s ease-in-out;
    
    @include media-breakpoint-up(lg){
        flex-direction: row;
        padding: .75rem 0;
    }
    @include media-breakpoint-up(xl){
        // justify-content: flex-end;
        padding: 0;
    }

    &__options {
        display: flex;
        align-items: center;
        gap: 1rem;
        
        @include media-breakpoint-up(lg){
            gap: 2rem;
        }
        @include media-breakpoint-up(xl){
            gap: 1rem;
        }
    }

    &__sizes {
        display: flex;
        align-items: flex-end;
        color: var(--theme-color-black-contrast);
        gap: 1rem;
        
        @include media-breakpoint-up(lg){
            gap: .5rem;
        }
        
        .size {
            display: inline-grid;
            place-content: center;
            background-color: transparent;
            color: inherit;
            border: none;
            font-size: 14px;
            width: 1.875rem;
            height: 1.875rem;
            border-radius: 0;
            font-weight: 500;
            padding: .25rem;
            white-space: nowrap;
            letter-spacing: 1px;
            transition: color .25s ease-in-out, background-color .25s ease-in-out;
            
            &--medium {
                font-size: 16px;
            }

            &:hover,
            &:focus-visible {
                color: var(--theme-color-green-white) !important;
            }
        }
    }

    &__btn {
        cursor: pointer;
        background-color: transparent;
        background: transparent;
        border: none;
        pointer-events: auto;
        transition: transform .5s cubic-bezier(0.075, 0.82, 0.565, 1.4);

        svg {
            margin: 0;
            
            path {
                fill: var(--theme-color-black-contrast);
                transform-origin: center;
                transition: fill .3s ease-in-out, transform  .5s cubic-bezier(0.175, 0.885, 0.32, 1);
            }
        }

        &:hover,
        &:focus-visible {
            path {
                fill: var(--theme-color-green-white);
                transform: rotate(180deg);
            }
        }

        &.disabled {
            pointer-events: none;
        }
    }
}