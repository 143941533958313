.blog-wrapper {
    @extend .row;
    --bs-gutter-y: 1.75rem;

    > div {
        &:not(:last-child){
            .box-blog {
                padding-bottom: 1.5rem;
                border-bottom: 1px solid var(--theme-color-gray-50);
            }
        }
    }

    .box-blog__thumb {
        @include media-breakpoint-up(xxl){
            height: 420px;
        }
    }
}