.accordion {
    border: 0;

    &__item {
        &:nth-child(odd){
            background-color: var(--theme-color-gray-20-black);
        }
        .accordion-button {
            font-size: .875rem;
            padding: 1rem .75rem;
            background-color: transparent;
            transition: color .25s ease-in-out;
            color: var(--theme-color-black-contrast);
            box-shadow: none;

            @include media-breakpoint-up(sm){
                font-size: 1rem;
            }

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.82 9L16 18.2713L25.18 9L28 11.8543L16 24L4 11.8542L6.82 9Z' fill='%23333333'/%3E%3C/svg%3E%0A"); 
                
                .wcag-active & {
                    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.82 9L16 18.2713L25.18 9L28 11.8543L16 24L4 11.8542L6.82 9Z' fill='%23FFFF00'/%3E%3C/svg%3E%0A"); 
                }
            }
            
            &:not(.collapsed){
                color: var(--theme-color-green-white);
                
                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.82 9L16 18.2713L25.18 9L28 11.8543L16 24L4 11.8542L6.82 9Z' fill='%2343861D'/%3E%3C/svg%3E%0A"); 
                    
                    .wcag-active & {
                        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.82 9L16 18.2713L25.18 9L28 11.8543L16 24L4 11.8542L6.82 9Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A"); 
                    }
                }
            }

            &:focus {
                box-shadow: none;
            }

            &:focus-visible {
                outline: 3px dotted var(--theme-color-outline);
                color: var(--theme-color-black-white);
            }
        }

        .accordion-body {
            padding: .5rem .75rem 1rem;

            &--files {
                display: flex;
                flex-direction: column;

                > div {
                    padding: .35rem 0;

                    &:not(:last-child){
                        border-bottom: 1px solid var(--theme-color-gray-50);
                    }

                    @include media-breakpoint-up(xl){
                        padding: .5rem 0;
                    }
                }

            }
        }

        .accordion-file {
            font-size: .875rem;

            a {
                display: flex;
                line-height: 1.4;
                color: var(--theme-color-black-contrast);
                gap: 1rem;
                justify-content: space-between;
                transition: color .25s ease-in-out;

                div {
                    &:nth-child(2){
                        white-space: nowrap;;
                        text-transform: uppercase;
                    }
                }

                &:hover,
                &:focus-visible {
                    color: var(--theme-color-green-white);
                }
            }
        }
    }
}