@mixin hover-active() {
    &:hover,
    &:focus,
    &:active {
        @content;
    }
}

@mixin target-blank {
    &[target="_blank"] {
        &::after {
            content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.58464 3.01408H12.7689L6.76986 9.0131L7.48692 9.7302L13.486 3.73105V7.91549H14.5001V2H8.58464V3.01408ZM3.51408 5.5493H7.2324V4.53522H3.51408H2.5V5.5493V12.9859V14H3.51408H10.9507H11.9648V12.9859V9.2676H10.9507V12.9859H3.51408V5.5493Z' fill='%2343861D'/%3E%3C/svg%3E%0A");
            vertical-align: middle;
            margin-left: 4px;
            
            .wcag-active & {
                content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.58464 3.01408H12.7689L6.76986 9.0131L7.48692 9.7302L13.486 3.73105V7.91549H14.5001V2H8.58464V3.01408ZM3.51408 5.5493H7.2324V4.53522H3.51408H2.5V5.5493V12.9859V14H3.51408H10.9507H11.9648V12.9859V9.2676H10.9507V12.9859H3.51408V5.5493Z' fill='%23FFFF00'/%3E%3C/svg%3E%0A");
            }
        }
    }
}