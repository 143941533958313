.social-bar {
    color: var(--theme-color-green);
    margin-bottom: 2rem;
    z-index: 4;
    background-color: var(--theme-color-white);

    .wcag-active & {
        background: none;
    }
  

    &__links {
        display: flex;
        gap: 1.5rem;
        font-weight: 500;
        justify-content: space-between;
    }

    &__print {
        font-weight: 500;
        padding: 0;
        margin: 0;
        border: 0;
    }

    a,
    button {
        transition: color .2s ease-in-out;

        &:hover,
        &:focus-visible {
            color: var(--theme-color-black-white);
        }
    }
}