.gtfsl {
    &__line {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
        padding: 0;
        margin: 0;

        > * {
            display: inline-block;
            width: 2.125rem;
            height: 1.875rem;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}