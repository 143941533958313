.hero {
    &__messages {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
    }

    h1 {
        @include media-breakpoint-down(xl){
            @include visually-hidden;
        }
    }

    .splide {
        @include media-breakpoint-up(sm){
            margin-bottom: 3rem;
        }
        @include media-breakpoint-up(xl){
            margin-bottom: 0;
        }

        &__img {
            width: 100%;
            height: 220px;
            margin-bottom: 1.25rem;

            @include media-breakpoint-up(sm){
                height: 350px;
            }
            @include media-breakpoint-up(md){
                height: 550px;
            }
            @include media-breakpoint-up(xl){
                height: 450px;
            }

            img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
            }
        }

        &__title {
            font-size: 1.5em;
            font-weight: 500;
            color: var(--theme-color-green);
            letter-spacing: -0.24px;
            margin-bottom: 1.25rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            line-height: 1.2;
            text-overflow: ellipsis;
            overflow: hidden;

            @include media-breakpoint-up(lg){
                -webkit-line-clamp: 1;
                line-clamp: 1;
            }
        }
        
        &__desc {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 1rem;
        }

        &__btn {
            padding-bottom: 1rem;

            @include media-breakpoint-down(sm){
                text-align: center;
            }
        }

        &__pagination {
            left: auto;
            right: 0;
            counter-reset: pagination-num;

            @include media-breakpoint-down(sm){
                margin-top: 1rem;
                position: relative;
            }

            &__page {
                width: 1.5rem;
                height: 2rem;
                opacity: 1;
                background-color: transparent;
                border-bottom: $theme-border;
                border-radius: 0;
                transition: border-color .25s ease-in-out;
                
                &::before {
                    font-size: $fs-body-small;
                    counter-increment: pagination-num;
                    content: counter( pagination-num );
                    color: var(--theme-color-black-contrast);
                    font-weight: 700;
                    background: transparent;
                }

                &:hover,
                &.is-active {
                    transform: scale(1);
                    border-color: var(--theme-color-green-white);
                }

                &:focus {
                    outline: none !important;
                    border-color: var(--theme-color-black-contrast);
                }
                
                &:focus-visible {
                    outline: 3px dotted var(--theme-color-outline) !important;
                    background-color: var(--theme-color-green-20);
                }
            }
        }
    }
}