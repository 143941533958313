h1 {
    line-height: 1.15;
    letter-spacing: -0.72px;
    
    @include media-breakpoint-up(lg){
        font-size: 2.25em;
    }
}

h2 {
    font-size: 1.5em;
    letter-spacing: -0.24px;
}

h3 {
    font-size: 1.1875em;
    letter-spacing: -0.095px;
}

h4,
h5,
h6 {
    font-size: 1em;
}

a {
    color: unset;
    text-decoration: none;
    
    &:hover {
        color: unset;
        text-decoration: none;
    }

    &:focus-visible {
        border-color: var(--theme-color-black-white);

        &.link {
            background-color: var(--theme-color-green-20);
        }
    }
}

.wcag-active {
    a,
    button {
        text-decoration: underline;

        &:not(.btn){
            &:hover {
                color: var(--theme-color-black-white);
            }
        }
    }
    
    a,
    button {
        &:hover,
        &:focus-visible {
            text-decoration: none;
        }
    }

    .form-text {
        color: var(--wcag-color-contrast);
    }
}