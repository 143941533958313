.table-tickets {
    margin-bottom: 2rem;
    
    tbody {
        tr {
            td {
                @include media-breakpoint-up(md){
                    &:nth-child(4){
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

.form-switches {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.5rem;
    flex-wrap: wrap;
    gap: 1rem;
    
    @include media-breakpoint-down(sm){
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 0;

        > div {
            &:not(:last-child){
                border-bottom: 1px solid var(--theme-color-gray-50);
            }
        }
    }

    .form-switch {
        display: inline-flex;
        padding: .75rem .5rem;
        margin-bottom: 0;

        .form-check-icon {
            margin-right: 0.25rem;

            svg {
                max-height: 26px;

                path {
                    .wcag-active & {
                        fill: var(--theme-color-green-black) !important;
                    }
                }
            }
        }

        input {
            float: right;
            margin-left: .75rem;
        }

        @include media-breakpoint-down(sm){
            width: 100%;
            flex-grow: 1;

            input {
                margin-left: auto;
            }
        }
    }
}

.offcanvas {
    background-color: var(--theme-color-white-black);

    .offcanvas-title {
        font-size: 1.125em;
        color: var(--theme-color-green);
        font-weight: 500;
    }

    .offcanvas-body {
        font-size: $fs-body-small;
        font-weight: 500;

        .__item {
            > div {
                margin-bottom: 0.4rem;
                
                div,
                span {
                    font-weight: 400;
                }

                .__bg {
                    margin-top: 0.25rem;
                    padding: .5rem .75rem;
                    background-color: var(--theme-color-gray-20-black);
                    border-radius: 2px;
                }

                .__opentime {
                    span {
                        float: right;
                    }
                }

            }

            &.__navigate {
                margin-bottom: 0.5rem;
                
                a {
                    text-align: center;
                    width: 100%;
                    padding: .8rem;
                }
            }

            &.__inactive {
                font-size: .875em;
                text-align: center;
                background-color: var(--theme-color-red);
                color: var(--theme-white);
                padding: .75rem;

                div {
                    margin: 0;
                }
            }
        }

    }

    .btn-close {
        opacity: .8;
        background-color: var(--theme-color-white-contrast);

        &:focus {
            box-shadow: none;
        }
    }
}

.contact-map {
    .yNHHyP-marker-view {
        svg {
            fill: var(--theme-color-green-70-black);

            &.__wcag {
                path {
                    .wcag-active & {
                        fill: var(--theme-color-green-black) !important;
                    }
                }
            }
        }
    }

    .gm-style-iw-d {
        color: var(--theme-color-black);

        a {
            transition: color .2s ease-out;
            color: var(--theme-color-green-black) !important;
        }
    }
}