header {
    @include media-breakpoint-down(xl){
        position: fixed;
        display: none;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100svh;
        overflow: hidden;
        z-index: 9;

        &.show {
            display: block;
        }
    }

    @include media-breakpoint-up(xl){
        display: block;
        position: sticky;
        top: 0;
        z-index: 99;
    }
}

.header {
    position: relative;
    background-color: var(--theme-color-white-black);
    z-index: 100;
}

.header-peka {
    position: relative;
    color: var(--theme-color-white-contrast);
    background-color: var(--theme-color-gravel);
    border-bottom: 6px solid var(--theme-color-green);
    padding: .65rem 0;
    z-index: 9;

    &__online {
        display: flex;
        gap: 1rem;
        align-items: center;

        @include media-breakpoint-down(lg){
            span {
                display: none;
            }
        }
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        gap: .75rem 1rem;

        .__app {
            display: block;

            path {
                fill: var(--theme-color-white-contrast);
            }
        }

        @include media-breakpoint-up(lg){
            gap: .75rem 1.25rem;

            .__app {
                display: none;
            }
        }
    }
}

.header-prenav {
    @extend .container-xxl;

    @include media-breakpoint-down(xl){
        display: block;
        position: sticky;
        top: 0;
        z-index: 99;
        background-color: var(--theme-color-white);
        transition: box-shadow .2s ease-in-out;

        &.sticky {
            box-shadow: 0px 4px 12px rgba(22, 22, 22, 0.08);
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        padding: .65rem 0;
        height: 65px;
        
        @include media-breakpoint-up(xl){
            height: initial;
            padding: 1.5rem 0;
            justify-content: space-between;
        }
    }

    &__options {
        display: flex;
        align-items: center;
        gap: .5rem 3rem;

        button {
            font-weight: 500;
        }

        .dropdown-menu {
            padding: .25rem .5rem;

            &.dropdown-languages {
                max-height: 200px;
                overflow-y: scroll;
            }
        }

        @include media-breakpoint-down(sm){
            .dropdown-menu {
                width: 100svw;
                text-align: center;
                margin-top: .25rem !important;
                box-shadow: none;
                border-bottom: 2px solid var(--theme-color-green);
            }
        }

        @include media-breakpoint-down(lg){
            gap: .5rem .75rem;
            margin: 0 .75rem;
            
            button {
                &:after {
                    display: none;
                }

                span {
                    display: none;
                }
            }
        }
    }

    &__logo {
        @include media-breakpoint-down(xl){
            margin-right: auto;

            svg {
                width: auto;
                max-height: 42px;
            }
        }
    }

    &__toggler {
        padding: 0;
        float: right;
        border: none;
        margin-left: 0.5rem;
        background-color: transparent;

        @include media-breakpoint-up(xl){
            display: none;
        }

        svg {
            margin: 0;

            .line {
                fill: none !important;
                stroke: var(--theme-color-black-contrast);
                stroke-width: 4;
                transition: stroke-dasharray .5s cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset .5s cubic-bezier(0.4, 0, 0.2, 1);
            }
            .line1 {
                stroke-dasharray: 60 207;
                stroke-width: 4;
            }
            .line2 {
                stroke-dasharray: 60 60;
                stroke-width: 4;
            }
            .line3 {
                stroke-dasharray: 60 207;
                stroke-width: 4;
            }
        }

        &.open {
            svg {
                .line1 {
                    stroke-dasharray: 90 207;
                    stroke-dashoffset: -134;
                    stroke-width: 4;
                }
                .line2 {
                    stroke-dasharray: 1 60;
                    stroke-dashoffset: -30;
                    stroke-width: 4;
                }
                .line3 {
                    stroke-dasharray: 90 207;
                    stroke-dashoffset: -134;
                    stroke-width: 4;
                }
            }
        }
    }

    .dropdown-languages {
        .glink {
            display: block;
            padding: .35rem .45rem;
            line-height: 1.15;
            color: var(--theme-color-black-contrast);
            border-bottom: $theme-border;
            transition: background-color .25s ease-in-out;

            &:hover {
                background-color: var(--theme-color-gray-20-black);
            }

            &.gt-current-lang,
            &:active {
                color: var(--theme-color-black-white);
                background-color: var(--theme-color-white-black);   

                &::before {
                    content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.88071 13.0967L1.36639 8.58241L2.6044 7.34527L5.88071 10.6216L12.8527 3.6496L14.089 4.88848L5.87984 13.0976L5.88071 13.0967Z' fill='%2345484A'/%3E%3C/svg%3E%0A");
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: .25rem;

                    .wcag-active &{
                        content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.88071 13.0967L1.36639 8.58241L2.6044 7.34527L5.88071 10.6216L12.8527 3.6496L14.089 4.88848L5.87984 13.0976L5.88071 13.0967Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
                    }
                }

                &:hover {
                    color: var(--theme-color-black-contrast);
                    background-color: var(--theme-color-gray-20-black);
                }
            }
        }
    }
}