.navbar {
    position: relative;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 0;
    //background-color: var(--theme-color-gray-20-black);
    background-color: var(--theme-color-green);
    transition: box-shadow 0.2s ease-in-out;

    @include media-breakpoint-down(xl) {
        align-items: flex-start;
        background-color: var(--theme-color-white-contrast);
        padding: 8rem 0 4rem;
        height: 100svh;
        overflow: scroll;
    }

    .wcag-active & {
        border-top: 1px solid var(--theme-color-green);
        border-bottom: 1px solid var(--theme-color-green);
        background-color: var(--theme-color-background);
    }

    &__main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @include media-breakpoint-up(xl) {
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            flex-basis: auto;
        }

        .navbar-social {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            padding: 0;
            margin: 0;
            list-style: none;

            @include media-breakpoint-up(xl) {
                gap: 1.375rem;
                justify-content: flex-start;
            }

            .nav-item {
                padding: 3px;
                overflow: hidden;

                &--show {
                    display: none;
                }
                &--hide {
                    transition: width 0.2s ease-out;
                }

                @include media-breakpoint-up(xl) {
                    svg {
                        display: block;
                        fill: var(--theme-color-white-contrast);
                    }
                }
                @include media-breakpoint-down(lg) {
                    svg {
                        display: block;
                        fill: var(--theme-color-gravel-contrast);
                    }
                }

                .wcag-active & {
                    svg {
                        display: block;
                        fill: var(--theme-color-white-contrast);
                    }
                }
            }
        }
    }

    &__nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0 0 2rem;
        gap: 0.5rem;

        @include media-breakpoint-up(xl) {
            margin: 0;
            gap: 3rem;
            flex-direction: row;
        }

        .nav-item {
            .nav-link {
                padding: 0.5rem;
                font-weight: 700;
                line-height: 1.2;
                border: $theme-border;

                @include media-breakpoint-down(lg) {
                    color: var(--theme-color-gravel-contrast);
                }
                @include media-breakpoint-up(xl) {
                    color: var(--theme-color-white-contrast);                    
                    padding: 1.125rem 0.5rem;
                }
               

                &::after {
                    display: none;
                }

                &.active,
                &:hover {
                    
                    @include media-breakpoint-down(lg) {
                        color: var(--theme-color-gravel-contrast);
                    }
                    @include media-breakpoint-up(xl) {
                        color: var(--theme-color-white-contrast);    
                    }

                    border-bottom-color: var(--theme-color-white-contrast);
                }

                &:active {

                    @include media-breakpoint-down(lg) {
                        border-color: var(--theme-color-gravel);
                        border-bottom-color: var(--theme-color-gravel);
                    }

                    @include media-breakpoint-up(xl) {
                        border-color: var(--theme-color-green-white);
                        border-bottom-color: var(--theme-color-white-contrast);
                    }
                }

                &:focus-visible {
                    color: var(--theme-color-black-white);
                    background-color: var(--theme-color-green-20);
                    border-bottom-color: var(--theme-color-black-white);
                }

            }

            &--home {
                svg {
                    display: none;
                }

                @include media-breakpoint-up(xl) {
                    svg {
                        display: block;
                        fill: var(--theme-color-white-contrast);
                    }
                    span {
                        display: none;
                    }
                }
            }

            &--mobile {
                @include media-breakpoint-up(xl) {
                    display: none;
                }
            }

            &.dropdown {
                position: unset;
                text-align: center;

                @include media-breakpoint-up(xl) {
                    text-align: start;
                }

                .dropdown-menu {
                    position: relative;
                    background-color: transparent;
                    padding: 0.5rem 0.75rem;
                    box-shadow: none;
                    max-width: 100%;

                    @include media-breakpoint-up(xl) {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 100%;
                        height: auto;
                        min-height: 350px;
                        max-height: 670px;
                        overflow-y: auto;
                        padding: 2rem 0;
                        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
                        background-color: var(--theme-color-white);
                    }

                    &__container {
                        @extend .container-xxl;
                        padding: 0;

                        @include media-breakpoint-up(xl) {
                            height: 100%;
                            max-height: inherit;
                        }
                        @include media-breakpoint-between(xl, xxl) {
                            padding-left: 2rem;
                            padding-right: 2rem;
                        }
                    }

                    &__row {
                        @extend .row;
                        height: 100%;
                        max-height: inherit;
                        overflow: auto;

                        @include media-breakpoint-up(xl) {
                            padding: 0;

                            &__4 {
                                .dropdown-menu__col {
                                    @include make-col(3);
                                }
                            }
                            &__3 {
                                .dropdown-menu__col {
                                    @include make-col(4);
                                }
                            }
                            &__2 {
                                .dropdown-menu__col {
                                    @include make-col(6);
                                }
                            }
                        }
                    }

                    &__col {
                        color: var(--theme-color-gravel-contrast);

                        @include media-breakpoint-down(xl) {
                            font-size: 0.875rem;
                            width: 50%;
                        }
                    }

                    &__subtitle {
                        font-weight: 700;
                        line-height: 1.2;
                        margin: 1rem 0 0.5rem;
                        color: var(--theme-color-green);

                        @include media-breakpoint-up(xl) {
                            font-size: 1.125em;
                            margin-bottom: 1.25rem;
                            margin-top: 0;
                        }
                    }

                    &__list {
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        li {
                            padding-bottom: 0.5rem;

                            @include media-breakpoint-up(xl) {
                                padding-bottom: 0.875rem;
                            }

                            a {
                                @include target-blank;
                                line-height: 1.2;
                                transition: color 0.2s ease-in-out;                                
                                &:hover,
                                &:focus-visible {
                                    color: var(--theme-color-green-white);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.sticky {
        box-shadow: 0px 4px 12px rgba(22, 22, 22, 0.1);

        .navbar-social {
            .nav-item {
                &--show {
                    display: block;
                }
                &--hide {
                    display: none;
                }
            }
            svg {
                display: block;

                @include media-breakpoint-down(lg) {
                    fill: var(--theme-color-gravel-contrast);
                }
                @include media-breakpoint-up(xl) {
                    fill: var(--theme-color-white-contrast);
                }
                
            }
            .wcag-active & {
                svg {
                    display: block;
                    fill: var(--theme-color-gravel-contrast);
                }
            }
        }
    }
}
