.file-attachments {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    .file-attachment {
        display: none;

        &.show {
            display: flex;
        }
    }
}

.contact-forms {
    margin: 3rem 0;
    padding: 3rem 0;
    background-color: var(--theme-color-gray-20-black);
}

.contact-rodo {
    font-size: .875em;
}

.contact-peka {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
    flex-direction: column;
    align-items: center;

    svg {
        max-height: 100%;
        height: auto;
        flex-shrink: 0;
    }

    @include media-breakpoint-up(sm){
        flex-direction: row;
    }
}

.contact-map {
    height: 320px;

    .gm-style-iw-chr {
        button {
            width: 24px !important;
            height: 24px !important;
    
            span {
                margin: 3px !important;
                width: 18px !important;
                height: 18px !important;
            }
        }
    }
    
    @include media-breakpoint-up(sm){
        height: 450px;
    }

    @include media-breakpoint-up(xxl){
        height: 550px;
    }
}

.contact-locations .container-xxl {
    background-color: var(--theme-white);
}

