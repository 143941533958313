.footer {
    padding: 3rem 0 2rem;
    background-color: var(--theme-color-gray-20-black);

    a {
        transition: color .2s ease-in-out;

        &:hover,
        &:focus-visible {
            color: var(--theme-color-green-white);
        }
    }

    &__logo {
        margin-bottom: 3rem;

        @include media-breakpoint-down(md){
            margin-bottom: 1.5rem;
            
            svg {
                width: auto;
                max-width: 100%;
                max-height: 70px;
            }
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            padding-bottom: 0.25rem;
        }
    }

    &__icon {
        &::before {
            vertical-align: middle;
            margin-right: 0.5rem;
        }

        &--email {
            &::before {
                content: url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.8 2H2.2C0.99 2 0.011 2.95625 0.011 4.125L0 16.875C0 18.0438 0.99 19 2.2 19H19.8C21.01 19 22 18.0438 22 16.875V4.125C22 2.95625 21.01 2 19.8 2ZM19.8 6.25L11 11.5625L2.2 6.25V4.125L11 9.4375L19.8 4.125V6.25Z' fill='%2343861D'/%3E%3C/svg%3E%0A");
            } 
            .wcag-active &::before {
                content: url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.8 2H2.2C0.99 2 0.011 2.95625 0.011 4.125L0 16.875C0 18.0438 0.99 19 2.2 19H19.8C21.01 19 22 18.0438 22 16.875V4.125C22 2.95625 21.01 2 19.8 2ZM19.8 6.25L11 11.5625L2.2 6.25V4.125L11 9.4375L19.8 4.125V6.25Z' fill='%23FFFF00'/%3E%3C/svg%3E%0A");
            } 
        }
        &--tel {
            &::before {
                content: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.62 9.79C7.06 12.62 9.38 14.93 12.21 16.38L14.41 14.18C14.68 13.91 15.08 13.82 15.43 13.94C16.55 14.31 17.76 14.51 19 14.51C19.55 14.51 20 14.96 20 15.51V19C20 19.55 19.55 20 19 20C9.61 20 2 12.39 2 3C2 2.45 2.45 2 3 2H6.5C7.05 2 7.5 2.45 7.5 3C7.5 4.25 7.7 5.45 8.07 6.57C8.18 6.92 8.1 7.31 7.82 7.59L5.62 9.79Z' fill='%2343861D'/%3E%3C/svg%3E%0A");
            } 
            .wcag-active &::before {
                content: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.62 9.79C7.06 12.62 9.38 14.93 12.21 16.38L14.41 14.18C14.68 13.91 15.08 13.82 15.43 13.94C16.55 14.31 17.76 14.51 19 14.51C19.55 14.51 20 14.96 20 15.51V19C20 19.55 19.55 20 19 20C9.61 20 2 12.39 2 3C2 2.45 2.45 2 3 2H6.5C7.05 2 7.5 2.45 7.5 3C7.5 4.25 7.7 5.45 8.07 6.57C8.18 6.92 8.1 7.31 7.82 7.59L5.62 9.79Z' fill='%23FFFF00'/%3E%3C/svg%3E%0A");
            } 
        }
        &--place {
            &::before {
                content: url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.22222 21C2.61111 21 2.08778 20.7791 1.65222 20.3373C1.21741 19.8947 1 19.3629 1 18.7419V7.45161C1 6.83065 1.21741 6.29887 1.65222 5.85629C2.08778 5.41446 2.61111 5.19355 3.22222 5.19355H7.66667V3.86694C7.66667 3.7164 7.68074 3.57075 7.70889 3.43C7.7363 3.28849 7.77778 3.15188 7.83333 3.02016C7.88889 2.88844 7.9537 2.76124 8.02778 2.63855C8.10185 2.51661 8.19444 2.39919 8.30556 2.28629L10.2222 0.33871C10.4444 0.112903 10.7037 0 11 0C11.2963 0 11.5556 0.112903 11.7778 0.33871L13.6944 2.28629C13.8981 2.49328 14.0556 2.73301 14.1667 3.00548C14.2778 3.27871 14.3333 3.56586 14.3333 3.86694V9.70968H18.7778C19.3889 9.70968 19.9122 9.93059 20.3478 10.3724C20.7826 10.815 21 11.3468 21 11.9677V18.7419C21 19.3629 20.7826 19.8947 20.3478 20.3373C19.9122 20.7791 19.3889 21 18.7778 21H3.22222ZM3.22222 18.7419H5.44444V16.4839H3.22222V18.7419ZM3.22222 14.2258H5.44444V11.9677H3.22222V14.2258ZM3.22222 9.70968H5.44444V7.45161H3.22222V9.70968ZM9.88889 18.7419H12.1111V16.4839H9.88889V18.7419ZM9.88889 14.2258H12.1111V11.9677H9.88889V14.2258ZM9.88889 9.70968H12.1111V7.45161H9.88889V9.70968ZM9.88889 5.19355H12.1111V2.93548H9.88889V5.19355ZM16.5556 18.7419H18.7778V16.4839H16.5556V18.7419ZM16.5556 14.2258H18.7778V11.9677H16.5556V14.2258Z' fill='%2343861D'/%3E%3C/svg%3E%0A");
            }
            .wcag-active &::before {
                content: url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.22222 21C2.61111 21 2.08778 20.7791 1.65222 20.3373C1.21741 19.8947 1 19.3629 1 18.7419V7.45161C1 6.83065 1.21741 6.29887 1.65222 5.85629C2.08778 5.41446 2.61111 5.19355 3.22222 5.19355H7.66667V3.86694C7.66667 3.7164 7.68074 3.57075 7.70889 3.43C7.7363 3.28849 7.77778 3.15188 7.83333 3.02016C7.88889 2.88844 7.9537 2.76124 8.02778 2.63855C8.10185 2.51661 8.19444 2.39919 8.30556 2.28629L10.2222 0.33871C10.4444 0.112903 10.7037 0 11 0C11.2963 0 11.5556 0.112903 11.7778 0.33871L13.6944 2.28629C13.8981 2.49328 14.0556 2.73301 14.1667 3.00548C14.2778 3.27871 14.3333 3.56586 14.3333 3.86694V9.70968H18.7778C19.3889 9.70968 19.9122 9.93059 20.3478 10.3724C20.7826 10.815 21 11.3468 21 11.9677V18.7419C21 19.3629 20.7826 19.8947 20.3478 20.3373C19.9122 20.7791 19.3889 21 18.7778 21H3.22222ZM3.22222 18.7419H5.44444V16.4839H3.22222V18.7419ZM3.22222 14.2258H5.44444V11.9677H3.22222V14.2258ZM3.22222 9.70968H5.44444V7.45161H3.22222V9.70968ZM9.88889 18.7419H12.1111V16.4839H9.88889V18.7419ZM9.88889 14.2258H12.1111V11.9677H9.88889V14.2258ZM9.88889 9.70968H12.1111V7.45161H9.88889V9.70968ZM9.88889 5.19355H12.1111V2.93548H9.88889V5.19355ZM16.5556 18.7419H18.7778V16.4839H16.5556V18.7419ZM16.5556 14.2258H18.7778V11.9677H16.5556V14.2258Z' fill='%23FFFF00'/%3E%3C/svg%3E%0A");
            }
        }
    }


    &__links {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 0 .65rem;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    &__developer {
        font-size: .75em;
    }
}
