.btn {
    position: relative;
    display: inline-block;
    font-size: .875em;
    padding: .6rem 1.25rem;
    line-height: 1.2;
    border: 1px solid var(--theme-color-green);
    color: var(--theme-color-white-contrast);
    background-color: var(--theme-color-green-black);
    overflow: hidden;
    text-align: center;
    z-index: 4;
    transition: background-color .25s ease-in-out, color .25s ease-in-out, border-color .25s ease-in-out;

    &::before {
        content: "";
        position: absolute;
        width: 150%;
        height: 175%;
        background-color: var(--theme-color-green-80);
        bottom: 0;
        left: -25%;
        right: 0;
        top: 0;
        border-radius: 10rem 10rem 0 0;
        transform-origin: 50% 100%;
        transform: scaleY(0);
        transition: transform .2s ease-out;
        z-index: -1;

        .wcag-active & {
            display: none;
        }
    }

    &:active,
    &:hover {
        color: var(--theme-color-white-black);
        background-color: var(--theme-color-green-70);
        border-color: var(--theme-color-green-70);
    }

    &:active {
        &::before {
            transform: scaleY(.95);
        }
    }

    &:focus-visible {
        color: var(--theme-color-black-white);
        background-color: var(--theme-color-green-20);
        border-bottom: $theme-border;
        border-bottom-color: var(--theme-color-black-white);
    }

    &--border {
        padding: .45rem 1.25rem;
        background-color: transparent;
        border: 1px solid var(--theme-color-white-contrast);

        &::before {
            background-color: var(--theme-color-green-20);
        }

        &:active,
        &:hover {
            border-color: var(--theme-color-white-contrast);
            color: var(--theme-color-black);
            background-color: var(--theme-color-gray-20);
        }
    }

    &--light-green {
        border-color: var(--theme-color-green);
        color: var(--theme-color-green);
        background-color: var(--theme-color-white);

        &:active,
        &:hover {
            color: var(--theme-color-white-black);
            background-color: var(--theme-color-green-70);
            border-color: var(--theme-color-green-70);
        }
    }

    &--inactive {
        border-color: var(--theme-color-gravel-white);
        color: var(--theme-color-gravel-white);
        background-color: var(--theme-color-white-contrast);

        &:active,
        &:hover {
            border-color: var(--theme-color-gravel-contrast);
            color: var(--theme-color-white-contrast);
            background-color: var(--theme-color-gravel-contrast);
        }

        .wcag-active & {
            background-color: var(--theme-color-black);
        }
    }
}

.link {
    font-weight: 500;
    line-height: 1.2;
    padding: .45rem .35rem;
    border: $theme-border;
    transition: color .2s ease-in-out, border-color .25s ease-in-out;
    
    &::after {
        content: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 12.355L5.34333 7L0 1.645L1.645 0L8.645 7L1.645 14L0 12.355Z' fill='%2345484A'/%3E%3C/svg%3E%0A");
        vertical-align: middle;
        margin-left: 0.75rem;

        .wcag-active &{
            content: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 12.355L5.34333 7L0 1.645L1.645 0L8.645 7L1.645 14L0 12.355Z' fill='%23FFED00'/%3E%3C/svg%3E%0A");
        }
    }

    &:active,
    &:hover {
        color: var(--theme-color-gravel-white);
        border-bottom-color: var(--theme-color-green-white);
    }

    &:active {
        border-color: var(--theme-color-green-white);
    }

    &:focus-visible {
        border-color: transparent;
        border-bottom-color: var(--theme-color-black-white);
    }

    &--green {
        color: var(--theme-color-green);

        &::after {
            content: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 12.355L5.34333 7L0 1.645L1.645 0L8.645 7L1.645 14L0 12.355Z' fill='%2343861D'/%3E%3C/svg%3E%0A");
        }

        &:hover {
            color: var(--theme-color-green);
        }

        &:focus-visible {
            color: var(--theme-color-black-white);
        }
    }
}