.content {

	&__thumb {
		@extend .box-blog__thumb;
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 1.25rem;
	}

	a {
		@include target-blank;
		color: var(--theme-color-green);
		transition: color .2s ease-in-out, background-color .2s ease-in-out;
		
		&:not([download]){
			text-decoration: underline;
		}

		&:active {
			text-decoration: none;
			color: var(--theme-color-green) !important;
		}

		&:hover,
		&:focus-visible {
			color: var(--theme-color-black-white);
		}

		&:focus-visible {
			background-color: var(--theme-color-green-20);
		}
	}

	ul {
		line-height: 1.4;
		padding-left: 1rem;
		
		li {
			list-style-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.65039 3.80005L7.98372 10.1334L1.65039 16.4667' stroke='%233D3D3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			padding-bottom: .6rem;
			padding-left: .125rem;
			
			.wcag-active &{
				list-style-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.65039 3.80005L7.98372 10.1334L1.65039 16.4667' stroke='%23FFFF00' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			}

			ul {
				margin: .25rem 0;
			}

			a {
				color: var(--theme-color-green);
				transition: color .25s ease-in-out;

				&.active {
					color: var(--theme-color-green-white) !important;
				}

				&:hover {
					color: var(--theme-color-black-white) !important;
				}
			}
		}
	}

	ol {
		li {
			padding-bottom: .6rem;
		}
	}

	figure {
		img {
			max-width: 100%;
			height: auto;
			border-radius: 2px;
		}

		figcaption {
			font-size: .875rem;
			text-align: center;
			padding-top: 0.25rem;
		}
	}

	iframe {
		border: 0;
		margin: 1.5rem auto;
		width: 100%;
		height: auto;
		aspect-ratio: 16 / 9;
	}

	table {
		margin: 1.5rem 0;
	}

	&--links {
		a {
			text-decoration: none;

			&:focus-visible {
				background-color: transparent;
			}
		}
	}

	.page-id-1004 & {
		&:nth-of-type(2) {
			p:nth-of-type(1),
			p:nth-of-type(2) {
				margin-bottom: .5rem;
			}
		}
	}
}

.gallery {
	display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    margin: 1.5rem 0;

	&__img {
		position: relative;
		display: block;
		max-width: 100%;
		height: 160px;
		border-radius: 2px;
		overflow: hidden;
		z-index: 9;
		
		@include media-breakpoint-up(sm){
			height: 220px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			transition: transform .3s ease-in-out;
		}

		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}

	&__desc {
		position: absolute;
		font-size: .75rem;
		text-align: center;
		line-height: 1.2;
		bottom: 0;
		left: 0;
		right: auto;
		margin: auto;
		width: 100%;
		padding: .5rem .75rem;
		background-color: rgba(70, 70, 70, 0.7);
		border-radius: 0 0 2px 2px;
		color: var(--theme-color-white-contrast);

		@include media-breakpoint-up(sm){
			font-size: .875rem;
		}
	}

	@include media-breakpoint-up(md){
		gap: 1rem;
		grid-template-columns: repeat(3, 1fr);

		&--2 {
			grid-template-columns: repeat(2, 1fr);

			.gallery__img {
				@include media-breakpoint-up(md){
					height: 300px;
				}
				@include media-breakpoint-up(xl){
					height: 400px;
				}
			}
		}
	}
}