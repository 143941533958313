.search-toggler {
    @include media-breakpoint-up(xl) {
        display: none;
    }
}

.search-box,
.proinput {
    display: block;
    width: 400px;

    .input-group {
        height: 40px;
        align-items: top;

        .btn {
            height: inherit;
        }
    }

    .submit {
        color: inherit;
        height: 40px;
        line-height: 50px;
        border: none;
        background: transparent;

        &:hover,
        &:focus,
        &:focus-visible {
            color: var(--theme-color-white) !important;
            outline: 3px dotted var(--theme-color-white);
        }
    }
}

.search-box {
    @include media-breakpoint-down(xl) {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        overflow: hidden;
        z-index: 9999;
    
        .input-group {
            height: 65px;
    
            button {
                display: none;
            }
        }
    
        &.show {
            display: block;
        }
    }

    .asl_w {
        box-shadow: none;
    }

    #ajaxsearchlite1 {
        .probox {
            border: 1px solid var(--theme-color-gray-50) !important;
            padding: 5px 0px !important;
            background-color: var(--theme-color-white) !important;
    
            .proinput {
                form {
                    input {
                        &.orig {
                            color: var(--theme-color-black-contrast) !important;
                
                            &::placeholder {
                                color: var(--theme-color-black-contrast) !important;
                            }
                        }
                    }
                }
            }

            input.autocomplete,
            input.orig {
                font-size: 1rem !important;
                color: var(--theme-color-black) !important;
            }

            .promagnifier {
                .innericon {
                    svg {
                        fill: var(--theme-color-green) !important;
                    }
                }
            }
        }

        .promagnifier {
            box-shadow: none;
        }

        .results {
            .asl_nores {
                color: var(--theme-color-black-contrast);
                background-color: var(--theme-color-white);
            }
        }
    }
}

.wpdreams_asl_results {
    &.vertical {
        margin-top: 0;
        color: var(--theme-color-black-contrast) !important;
        background-color: var(--theme-color-white) !important;

        .results {
            scrollbar-color: rgb(67, 134, 29) transparent;

            .wcag-active &{
                scrollbar-color: rgb(255, 255, 0) transparent;
            }

            .item {
                background-color: var(--theme-color-white); 
                border-left: 0;
                border-right: 0;

                .asl_content {
                    h3 {
                        a {
                            color: var(--theme-color-green) !important;
                            transition: color .2s;
        
                            &:hover {
                                color: var(--theme-color-black-white) !important;
                            }
                        }
                    }
                }
                
                &.hovered {
                    background-color: var(--theme-color-white) !important; 

                    h3 {
                        a {
                            outline: 2px dotted var(--theme-color-outline);
                            color: var(--theme-color-black-white) !important;
                        }
                    }
                    
                }

            }
        }
    }
}

.search-result {
    display: block;
    border-radius: 0;
    padding: 1rem .75rem;
    height: calc(100% - 2rem);
    margin-bottom: 2rem;
    background-color: var(--theme-color-gray-20-black);
    transition: background-color .3s ease-in-out;

    .wcag-active & {
        border: 1px solid var(--theme-color-green);
    }

    &__title {
        font-size: 1.125em;
        line-height: 1.2;
        font-weight: 500;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        box-orient: vertical;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__desc {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        box-orient: vertical;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 0.5rem;
    }

    &:hover,
    &:focus-visible {
        background-color: transparent;
    }
}