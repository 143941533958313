@media print {

    header,
    aside,
    footer,
    .header-peka,
    .header-prenav,
    .btn,
    .gallery,
    .breadcrumbs,
    .__icon,
    .dropdown--apiloader,
    .station__buttons,
    .social-bar, .cky-btn-revisit-wrappe {
        display: none !important;
    }

    * {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    @page {
        // size: landscape letter;
    }

    html {
        transform: scale(0.8);
    }

    .container-xxl {
        max-width: 100%;
    }

    body,
    .line-table,
    .line-planner__desc {
        font-size: 14px !important;
    }

    .col-print {
        width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .page-template-page-timetable {
        hr {
            display: none;
        }
    }

    // Resetowanie marginesów i paddingu, jeśli rozciągają elementy
    body {
        margin: 0;
        padding: 0;
    }

    // Upewnij się, że kontenery Bootstrapa mają odpowiednią szerokość
    .container,
    .container-fluid {
        width: 100%;
        max-width: 100%;
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    
            @for $i from 1 through $grid-columns {
    
                @media print {
                    .col-print#{$infix}-#{$i} {
                        @include make-col($i, $grid-columns);
                    }
                }
            }
        }
    }

}