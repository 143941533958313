// Bootstrap
button {
    padding: .25rem .35rem;
    color: var(--theme-color-green);
    background-color: transparent;
    border: $theme-border;
    transition: border-color .25s ease-in-out;

    svg {
        margin-right: .35em;
    }

    &:hover {
        border-bottom-color: var(--theme-color-green-white);
    }

    &.show,
    &:active {
        border-color: var(--theme-color-green-white);
    }

    &:focus-visible {
        background-color: var(--theme-color-green-20);
        border-bottom-color: var(--theme-color-black-white);

        .wcag-active & {
            border-color: var(--theme-white);
        }
    }
}

table {
    @extend .table;
    @extend .table-striped;
    font-size: .875rem;
    line-height: 1.4;

    &:not(caption)>*>* {
        padding: .5rem .875rem;
        box-shadow: none !important;
    }

    &.table-center {
        > * {
            vertical-align: middle;
        }
    }

    thead {
        tr {
            th,
            td {
                font-weight: 500;
                line-height: 1.2;
                padding: .875rem .75rem !important;
                vertical-align: middle;
                color: var(--theme-color-white-black) !important;
                background-color: var(--theme-color-green) !important;

                &.__address {
                    min-width: 150px;
                }
                &.__time {
                    min-width: 115px;
                    white-space: nowrap;
                }

                @include media-breakpoint-up(md){
                    &.__address {
                        min-width: 180px;
                    }
                }
            }
        }
    }
    
    tbody {
        tr {
            td,
            th {
                color: var(--theme-color-black-contrast) !important;

                a {
                    transition: color .2s ease-in-out;
                    
                    &:hover,
                    &:focus-visible {
                        color: var(--theme-color-green-white);
                    }
                }
            }
        }
    }

    &.table--tickets {
        tbody {
            tr {
                td {
                    span {
                        display: none;
                        margin-right: .15rem;
                        font-weight: 500;
                    }
                }
            }
        }

        @include media-breakpoint-down(md){
            thead {
                display: none;
            }

            tbody {
                display: flex;
                flex-direction: column;

                tr {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0;

                    td {
                        width: 50%;
                        border-top: 1px solid var(--theme-color-gray-50);

                        span {
                            display: inline-block;
                        }

                        &:first-child(){
                            color: var(--theme-color-white-black) !important;
                            background-color: var(--theme-color-green);
                            border-top: 0;
                            width: 100%;
                        }

                        &:nth-child(3),
                        &:nth-child(5) {
                            border-right: 1px solid #ccc;
                        }

                        &:nth-child(2),
                        &:last-child() {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.form-control {
    border-radius: 0;
}

.form-check {
    input {
        background-color: var(--theme-color-white-contrast);

        &:focus {
            box-shadow: none;
            border-color: var(--theme-color-green-white);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2343861D'/%3e%3c/svg%3e") !important;
            
            .wcag-active &{
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000000'/%3e%3c/svg%3e") !important;
            }
        }

        &:checked,
        &:active {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e") !important;
            
            .wcag-active &{
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000000'/%3e%3c/svg%3e") !important;
            }
        }
    }
}

.input-group-text {
    border-radius: 0;
    border-color: var(--theme-color-gray-50);
    color: var(--theme-color-black-contrast);
    background-color: var(--theme-color-gray-20-black);
}

.dropdown {
    .dropdown-toggle {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        transition: opacity .2s ease-in-out, border-color .2s ease-in-out;

        &::after {
            content: "";
            border: none !important;
            flex-shrink: 0;
            width: 1rem;
            height: 1rem;
            margin-left: .25em;
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.82 9L16 18.2713L25.18 9L28 11.8543L16 24L4 11.8542L6.82 9Z' fill='%2343861D'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 1rem;
            transition: transform .25s ease-out;

            @include media-breakpoint-up(sm){
                width: 1.25rem;
                height: 1.25rem;
                background-size: 1.25rem;
            }

            .wcag-active & {
                background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.82 9L16 18.2713L25.18 9L28 11.8543L16 24L4 11.8542L6.82 9Z' fill='%23FFED00'/%3E%3C/svg%3E%0A");
            }
        }

        &.show {
            &::after {
                transform: rotate(-180deg);
            }
        }

        &.loading {
            position: relative;
            opacity: .6;
        }
    }

    .dropdown-menu {
        border: 0;
        border-bottom: $theme-border;
        border-radius: 0;
        background-color: var(--theme-color-white-black);
        box-shadow: 0px 4px 12px rgba(29, 29, 29, 0.12);
        
        .dropdown-item {
            padding: .35rem .45rem;
            line-height: 1.15;
            color: var(--theme-color-black-contrast);
            border-bottom: $theme-border;
            transition: background-color .25s ease-in-out;

            &:hover {
                background-color: var(--theme-color-gray-20-black);
            }

            &.active,
            &:active {
                color: var(--theme-color-black-white);
                background-color: var(--theme-color-white-black);   

                &::before {
                    content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.88071 13.0967L1.36639 8.58241L2.6044 7.34527L5.88071 10.6216L12.8527 3.6496L14.089 4.88848L5.87984 13.0976L5.88071 13.0967Z' fill='%2345484A'/%3E%3C/svg%3E%0A");
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: .25rem;

                    .wcag-active &{
                        content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.88071 13.0967L1.36639 8.58241L2.6044 7.34527L5.88071 10.6216L12.8527 3.6496L14.089 4.88848L5.87984 13.0976L5.88071 13.0967Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
                    }
                }

                &:hover {
                    color: var(--theme-color-black-contrast);
                    background-color: var(--theme-color-gray-20-black);
                }
            }
            
            &:focus-visible {
                background-color: var(--theme-color-green-20);
                border-color: var(--theme-color-black-contrast);
            }

            &--static {
                &.active,
                &:active {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    .loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 9;
    }

    &--border {
        display: inline-block;
        
        .dropdown-toggle {
            gap: .25rem;
            color: var(--theme-color-black-contrast);
            padding: .65rem;
            border: 1px solid var(--theme-color-gray-50);
        }

        .dropdown-menu {
            width: 100%;
            padding: .35rem .5rem;

            li {
                .dropdown-item {
                    display: inline-block;
                    text-align: center;
                    padding: .5rem .35rem !important;

                    &:active {
                        &::before {
                            display: none !important;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(sm){
            width: 100%;
            
            .dropdown-toggle {
                width: 100%;
                gap: .125rem;
                padding: .65rem .35rem;

                // .__package {
                //     display: none;
                // }
            }
        }
    }
}

.loader {
    color: var(--theme-color-green);
}

.form-control,
input[type=text] {
    position: relative;
    color: var(--theme-color-black-contrast);
    background-color: var(--theme-color-white);
    border: 1px solid var(--theme-color-gray-50);
    padding: .45rem .65rem;
    box-shadow: none;
    border-radius: 0;

    &:hover {
        background-color: var(--theme-color-white);
        box-shadow: inset 0 -2px 0 0 var(--theme-color-green-white);
        border-bottom-color: var(--theme-color-green-white);
    }

    &:focus {
        background-color: var(--theme-color-white);
        box-shadow: inset 0 0 0 2px var(--theme-color-green-white);
        border-color: var(--theme-color-green-white);
    }

    // &:focus-visible {
    //     background-color: var(--theme-color-green-20);
    // }

    &::placeholder {
        color: var(--theme-color-gravel-contrast);
    }
}

// Recaptcha
.grecaptcha-badge {
	display: none !important;
}

// Wordpress
.alignleft, img.alignleft {
	margin-right: 1.5em;
    float: left;
    max-width: 100%;
    height: auto;
}

.alignright, img.alignright {
	margin-left: 1.5em;
    float: right;
    max-width: 100%;
    height: auto;
}

.aligncenter, img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
    clear: both;
    max-width: 100%;
    height: auto;
}

.alignnone, img.alignnone {
    max-width: 100%;
    height: auto;
}

// Contact Form
.wpcf7 {
    .col-cf7 {
        margin-bottom: 1.25rem;
        
        @include media-breakpoint-up(sm){
            margin-bottom: 1.5rem;
        }

        p {
            margin: 0;
        }
    }

    label {
        padding-bottom: 0.35rem;
    }
    
    .wpcf7-form-control {
        &.wpcf7-not-valid {
            border-color: #C81939;
        }
    }

    .wpcf7-textarea {
        min-height: 200px;
    }

    .wpcf7-not-valid-tip {
        font-size: 12px;
        font-weight: 400;
        margin-top: .25rem;
        color: #C81939;
    }

    .file-attachment {
        display: flex;

        .wpcf7-form-control-wrap {
            max-width: calc(100% - 2rem);
        } 

        .wpcf7-file {
           max-width: 100%;

           &.wpcf7-not-valid {
                margin-bottom: 1rem;

                +.wpcf7-not-valid-tip {    
                    position: absolute;
                    bottom: -0.5rem;
                    left: 0;
                }
           }
        }
        
        .clear-file {
            display: flex;
            padding-top: .2rem;
            margin-left: 1rem;
        }
    }
    
    .wpcf7-response-output {
        font-size: 14px;
        text-align: center;
        padding: .5rem 1.5rem !important;
        margin: 2em auto 1em !important;
        border: 1px solid var(--theme-color-green) !important;
        //background-color: var(--theme-color-gray-50);
        color: var(--theme-color-black);
        border-radius: 0;
        width: fit-content;
        max-width: calc(100% - 1.5rem);
    }

    form.invalid {
        .wpcf7-response-output {
            color: var(--theme-color-red);
            border: 1px solid var(--theme-color-red) !important;
            //background-color: #C81939;
        }
    }

    form.sent {
        .wpcf7-response-output {
            color: var(--theme-color-green);
            border: 1px solid var(--theme-color-green) !important;
            //background-color: var(--theme-color-green);
        }
    }
    
    .wpcf7-spinner {
        display: none;
    }

    .wpcf7-checkbox {
        .wpcf7-list-item {
            font-size: 14px;
            padding-top: .15rem;
            margin-left: 0 !important;

            label {
                position: relative;
                padding: 0;
            }
    
            input[type="checkbox"] {
                position: absolute;
                top: 0;
                left: 0;
                width: 22px;
                height: 22px;

                + span {
                    &:before {
                        display: block;
                        position: absolute;
                        content: '';
                        border-radius: 0;
                        height: 22px;
                        width: 22px;
                        top: 0px;
                        left: 0px;
                        border: 2px solid var(--theme-color-green);
                        background-color: var(--theme-color-white-black);
                        transition: background-color .25s ease-out;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        width: 14px;
                        height: 7px;
                        border-bottom: 2px solid var(--theme-color-white-black);
                        border-left: 2px solid var(--theme-color-white-black);
                        top: 9px;
                        left: 9px;
                        transform-origin: bottom left;
                        transform: rotate(-45deg);
                        opacity: 0;
                        transition: opacity .25s ease-out;
                    }
                }

                &:hover,
                &:focus-visible,
                &:checked {
                    + span {
                        &:before {
                            background: transparent;
                            background-color: var(--theme-color-green);
                        }

                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }

            .wpcf7-list-item-label {
                display: inline-block;
                line-height: 1.25;
                margin-left: 2rem;
            }
        }
    }

    .wpcf7-submit {
        &:disabled {
            opacity: .85;
        }
    }
}