.box-news {
    border: 1px solid var(--theme-color-gray-50);
    padding: .75rem;
    
    @include media-breakpoint-up(sm){
        padding: 1.25rem;
    }

    &__title {
        font-size: 1.1875em;
        font-weight: 500;
        line-height: 1.25;
        color: var(--theme-color-green);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        text-overflow: ellipsis;
        overflow: hidden;

        @include media-breakpoint-up(md){
            min-height: 4.5rem;
        }
    }

    &__period {
        font-size: $fs-body-small;
        line-height: 1.25;
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
    }

    &__category {
        display: flex;
        gap: .75rem;
        margin: .5rem 0;
    }

    &__link {
        flex-shrink: 0;
    }
}