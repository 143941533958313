html {
    --theme-white: #FFFFFF;

    --theme-color-black: #333333;
    --theme-color-gravel: #45484A;
    --theme-color-green: #43861D;
    --theme-color-red: #C81939;
    --theme-color-white: #FFFFFF;
    
    --theme-color-green-80: #224d09;
    --theme-color-green-70: #2e650e;
    --theme-color-green-20: #d9e7d2;
    --theme-color-green-10: #F5FBF7;
    --theme-color-gray-50: #D9DADB;
    --theme-color-gray-20: #F6F6F6;
    
    --theme-color-outline: #db1d1d;

    --theme-color-background: var(--theme-color-white);
    --theme-color-text: var(--theme-color-black);

    --theme-color-black-contrast: var(--theme-color-black);
    --theme-color-gravel-contrast: var(--theme-color-gravel);
    --theme-color-white-contrast: var(--theme-color-white);

    --theme-color-white-black: var(--theme-color-white);
    --theme-color-green-black: var(--theme-color-green);
    
    --theme-color-black-white: var(--theme-color-black);
    --theme-color-gravel-white: var(--theme-color-gravel);
    --theme-color-green-white: var(--theme-color-green);

    --theme-color-gray-20-black: var(--theme-color-gray-20);

    --theme-color-green-70-black: var(--theme-color-green-70);

    --line-zone-A: #33a864;
    --line-zone-B: #d70000;
    --line-zone-C: #e6e600;
    --line-zone-D: #0B93F6;
}