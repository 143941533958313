.home-news {
    display: none;

    @include media-breakpoint-up(md){
        display: block;       
    }

    &__grid {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    
    @include media-breakpoint-only('xxl') {
        & .container-xxl{ 
            padding-top:1rem;
        }
    }
}

.home-boxes {
    margin: 2rem 0 0;
    padding: 2rem 0 1rem;
    
    @include media-breakpoint-up(md){
        margin: 3rem 0;
        padding: 2rem 0;
        // background-image: url('./../img/svg/decor-bar.svg');
        // background-repeat: no-repeat;
        // background-position: center;
        // background-color: transparent;
        // background-size: cover;
        background-color: var(--theme-color-gray-20);        
    }

    .wcag-active & {
        border-top: 1px solid var(--theme-color-green);
        border-bottom: 1px solid var(--theme-color-green);
        background-image: none;
        background:none;
    }
}