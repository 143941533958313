html {
	scroll-behavior: smooth;
}

body {
	position: relative;
	font-size: 1em;
	height: 100%;
	line-height: 1.5;
	font-family: $font-prime;
	color: var(--theme-color-text);
	background-color: var(--theme-color-background);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.size--small {
		font-size: 14px;
	}

	&.size--normal {
		font-size: 16px;
	}

	&.size--big {
		font-size: 18px;
	}

	&.size--huge {
		font-size: 20px;
	}
}

.container-xxl {
	@include media-breakpoint-between(sm,xxl){
		padding-left: 2.75rem;
		padding-right: 2.75rem;
	}
}

.wcag-active {
	img {
		filter: grayscale(1);
	}

	svg {
		path {
			fill: var(--theme-color-green);
			
			&.svg-stroke {
				fill: none;
				stroke: var(--theme-color-green);
			}
		}
	}
}

:focus-visible {
	outline: 2px dotted var(--theme-color-outline);

	.wcag-active & {
		color: var(--theme-color-black-white);
	}
}