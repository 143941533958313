.pagination {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .75rem;
    padding: 0;
    margin-bottom: 0.5rem;

    @include media-breakpoint-down(md){
        justify-content: center;
    }

    .page-item {
        .page-link {
            display: block;
            font-weight: 400;
            line-height: 1.75rem;
            width: 2rem;
            height: 2rem;
            text-align: center;
            background-color: transparent;
            border-bottom: $theme-border;
            border-radius: 0;
            transition: border-color .25s ease-in-out;

            &.next,
            &.prev {
                line-height: 1.75rem;
                border: 1px solid var(--theme-color-gray-50);
            }

            &.dots {
                border: none;
            }

            path {
                fill: var(--theme-color-green);
            }

            &:hover,
            &:focus-visible {
                color: var(--theme-color-black-white);
                border-color: var(--theme-color-green-white);
            }
        }

        &.active {
            .page-link {
                border-color: var(--theme-color-green-white);
            }
        }
    }
}